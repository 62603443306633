import React from 'react';

import { DocumentTitle } from 'shared/components/document-title/document-title.component';
import { useResources } from 'app/hooks/store/use-resources.hook';

import './page-header.component.scss';

interface HeaderFunctionProps {
  header: ResourceKey;
  documentTitle?: ResourceKey;
  buttonBay?: (() => JSX.Element | JSX.Element[]) | JSX.Element;
  buttonBaySuppressMobile?: boolean;
}
interface HeaderResourceProps {
  header:  (() => JSX.Element) | JSX.Element;
  documentTitle: ResourceKey;
  buttonBay?: (() => JSX.Element | JSX.Element[]) | JSX.Element;
  buttonBaySuppressMobile?: boolean;
}

type Props = HeaderFunctionProps | HeaderResourceProps;

export const PageHeader: React.FC<Props> = ({header, buttonBay, buttonBaySuppressMobile, documentTitle}) => {
  const resources = useResources();

  const headerValue = (typeof header === 'function' ? header() : (typeof header === 'string' ? resources[header] : header));
  const buttonBayValue = typeof buttonBay === 'function' ? buttonBay() : buttonBay;
  const documentTitleValue = documentTitle || (typeof header === 'string' && header as ResourceKey) || 'NAVISPHERE_CARRIER';

  if (typeof header === 'function' && !documentTitle) {
    throw new Error('Error: Page Header must include a document title resource key when header is of type function');
  }

  return (
    <div className={'page-header-wrapper flex flex-space-between flex-align-items-end' + (buttonBaySuppressMobile ? ' flex-wrap' : ' mobile-block')}>
      {documentTitleValue && <DocumentTitle resource={documentTitleValue}/>}
      <h1 className="page-header">{headerValue}</h1>
      {buttonBayValue &&
        <div className={buttonBaySuppressMobile ? 'text-right' : 'button-bay_mobile-responsive text-right'}>
          {buttonBayValue}
        </div>
      }
    </div>
  );
};
